import Courses from "./Courses.jsx";
import Hero from "./Hero.jsx";

export default function App() {
  return (
    <div className="bg-white">
      <Hero />
      <Courses />
    </div>
  );
}